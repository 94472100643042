import { AbstractControl } from '@angular/forms';

export function linkedinUrl(control: AbstractControl) {
  const value = control.value;
  const regex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?([a-z0-9]+[.])*linkedin[.]com(:[0-9]{1,5})?(\/.*)?$/;

  if (!value) {
    return;
  }

  if (!value.match(regex)) {
    return {
      validLinkedinUrl: true
    };
  }

  return null;
}