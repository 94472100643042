// native
import { ErrorHandler, Injectable } from '@angular/core';

// trackJS
import { TrackJS } from 'trackjs';

// service
import { UserService } from './core/services/user.service';

// environment
import { environment } from '../environments/environment';

@Injectable()
export class AppErrorHandler implements ErrorHandler {

  constructor(
    protected userService: UserService
  ) { }

  handleError(error: any) {
    if (!environment.trackJsEnabled) {
      throw error;
    }

    this.userService.user$.subscribe(user => {
      user && user.id && TrackJS.addMetadata('userId', user.id);
      TrackJS.track(error.originalError || error);
    }, () => {
      TrackJS.track(error.originalError || error);
    });
  }
}
