// native
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Data } from '@angular/router';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

// utilities
import { scrollToTop } from 'src/app/utilities/utilities';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  currentRouteData(): Observable<Data> {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      tap(()=> scrollToTop()),
      map((route: ActivatedRoute) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      mergeMap(route => route.data)
    );
  }
}
