// native
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// service
import { ApiService } from './api.service';

// environment
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InstitutionService {

  constructor(
    private apiService: ApiService
  ) { }

  getInstitutions(term: string): Observable<any> {
    const endpointUrl = `${environment.baseUrls.services}/institutions/search?term=${encodeURIComponent(term)}`;

    return this.apiService.get(endpointUrl);
  }
}