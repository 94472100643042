// native
import { NgModule, ModuleWithProviders, ErrorHandler } from "@angular/core";
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppHttpInterceptor } from '../app-http-interceptor.service';

// service
import { ApiService } from './services/api.service';
import { UserService } from './services/user.service';
import { SearchService } from './services/search.service';
import { ScriptLoadingService } from './services/script-loading.service';
import { InstitutionService } from './services/institution.service';
import { RoutingService } from './services/routing.service';
import { LocalStorageService } from './services/local-storage.service';
import { AnalyticsService } from './services/analytics.service';
import { FreshchatService } from './services/freshchat.service';
import { AppErrorHandler } from '../app-error-handler';

// component
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';
import { AuthComponent } from './components/auth/auth.component';

// module
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    AuthComponent
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    AuthComponent
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ApiService,
        UserService,
        SearchService,
        ScriptLoadingService,
        InstitutionService,
        RoutingService,
        LocalStorageService,
        AnalyticsService,
        FreshchatService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AppHttpInterceptor,
          multi: true
        },
        {
          provide: ErrorHandler,
          useClass: AppErrorHandler
        }
      ]
    };
  }
}