// native
import {
  Component,
  Input,
  Inject,
  ChangeDetectorRef,
  forwardRef,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  Renderer2,
} from '@angular/core';

import { DOCUMENT } from '@angular/common';

import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR
} from '@angular/forms';

import {
  Observable,
  fromEvent,
  Subscription
} from 'rxjs';

import {
  map,
  filter,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap
} from 'rxjs/operators';

// model
import { IAutocompleteItem } from 'src/app/models/autocomplete-item.model';

@Component({
  selector: 'rcw-autocomplete',
  templateUrl: './autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true
    }
  ],
  host: {
    '(document:click)': 'onOutsideClick($event)',
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutocompleteComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {

  @Input() isError: boolean = false;
  @Input() isSelectionLimited?: boolean = false;
  @Input() selectionPool?: IAutocompleteItem[] = [];
  @Input() maxListSize: number = 25;

  @Input() onInputCallback: (value: string) => Observable<any>;

  @ViewChild('input') inputElement: ElementRef<HTMLInputElement>;
  @ViewChild('results') resultsElement: ElementRef<HTMLElement>;

  inputValue: string;
  items: IAutocompleteItem[];

  autocompleteStream$: Observable<IAutocompleteItem[]>;
  autocompleteSubscription: Subscription;

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: HTMLDocument
  ) { }

  ngAfterViewInit() {
    this.createInputStream();
    this.createAutocompleteSubscription();
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }

  writeValue(value: string) {
    this.inputValue = value;
    this.changeDetectorRef.markForCheck();
  }

  private onSelect(item: IAutocompleteItem) {
    this.inputElement.nativeElement.value = item.name;
    this.propagateChange(item.name);
    this.clearList();
  }

  clearList() {
    this.resultsElement.nativeElement.innerHTML = '';
    this.items = [];
  }

  ngOnDestroy() {
    this.autocompleteSubscription && this.autocompleteSubscription.unsubscribe();
  }

  onOutsideClick(event) {
    if (!this.inputElement || !this.resultsElement) {
      return;
    }

    if (!this.inputElement.nativeElement.contains(event.target)
      && !this.resultsElement.nativeElement.contains(event.target)) {
      this.clearList();
    }
  }

  onInputFocus() {
    const inputEvent = new Event('input', {
      bubbles: true,
      cancelable: true,
    });

    this.inputElement.nativeElement.dispatchEvent(inputEvent);
  }

  private createInputStream() {
    this.autocompleteStream$ = fromEvent(this.inputElement.nativeElement, 'input')
      .pipe(
        map((event: Event) => (<HTMLInputElement>event.target).value),
        debounceTime(100),
        tap((term: string) => {
          if (!term) {
            this.propagateChange(null);
            return;
          }

          !this.isSelectionLimited && this.propagateChange(term);
          this.isSelectionLimited && this.setPropagation(term);

          term.length && (term.length < 2) && this.clearList();
        }),
        filter((term: string) => term.length >= 2),
        switchMap((term: string) => this.onInputCallback(term))
      );
  }

  private setPropagation(term: string) {
    if (!this.selectionPool.length) {
      this.propagateChange(term);
      return;
    }

    const matchedItem = this.selectionPool.find(item => {
      return item.name.toLowerCase() === term.toLowerCase();
    });

    !!matchedItem ? this.propagateChange(matchedItem.name) : this.propagateChange(null);
  }

  private createAutocompleteSubscription() {
    this.autocompleteSubscription = this.autocompleteStream$.subscribe((items: IAutocompleteItem[]) => {
      this.clearList();

      this.items = items.slice(0, this.maxListSize);

      this.items.map((item: IAutocompleteItem) => {
        const itemElement = this.document.createElement('div');
        itemElement.textContent = item.name;
        itemElement.addEventListener('click', () => this.onSelect(item));

        this.renderer.appendChild(this.resultsElement.nativeElement, itemElement);
      });

      this.changeDetectorRef.markForCheck();
    });
  }
}