// native
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, truncateCount: number = 300): string {
    if (!value) { return ''; }

    return value.slice(0, truncateCount)+ '...';
  }
}