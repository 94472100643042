// native
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

// service
import { UserService } from '../../services/user.service';

// model
import { IUser } from 'src/app/models/user.model';

// meta
import { BUILD_VERSION } from 'build-metadata/build';

@Component({
  selector: 'rcw-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  user$: Observable<IUser>;
  buildVersion: string;
  isUserRegistered: boolean = true;

  constructor(
    private userService: UserService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.user$ = this.userService.user$;
    this.buildVersion = BUILD_VERSION;

    this.userService.isUserRegistered$.subscribe((isRegistered: boolean) => {
      this.isUserRegistered = isRegistered;
    });
  }

  onLogout(): void {
    this.userService.logout();
  }

  onLogin(event): void {
    event.stopPropagation();
    this.userService.isLoginFrameShown$.next(true);
  }
}