<div class="autocomplete">
  <input
    #input
    class="form-control autocomplete_input"
    type="text"
    autocomplete="new-password"
    [(ngModel)]="inputValue"
    [ngClass]="{ 'form-control--error': isError }"
    (focus)="onInputFocus()"/>
  
  <ul
    #results 
    class="autocomplete_list"
    [ngClass]="{ 'hidden': !items || !items.length }">
  </ul>
</div>