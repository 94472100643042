// native
import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'rcw-field-error',
  templateUrl: './field-error.component.html'
})
export class FieldErrorComponent implements OnInit {

  @Input() control: FormControl;
  @Input() fieldName?: string;

  constructor() { }

  ngOnInit(): void {
  }
}
