// native
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { of, Observable, Subscription, BehaviorSubject } from 'rxjs';
import { DOCUMENT } from '@angular/common';

// service
import { UserService } from 'src/app/core/services/user.service';
import { InstitutionService } from 'src/app/core/services/institution.service';
import { AnalyticsService } from 'src/app/core/services/analytics.service';

// model
import { IUser } from 'src/app/models/user.model';
import { IAutocompleteItem } from 'src/app/models/autocomplete-item.model';
import { EnterProgramRequestModel } from 'src/app/models/enter-program.model';

// constants
import { COVID_PROGRAM, COUNTRIES, PROGRAM_ROLES, OTHER } from '../../../constants/constants';

// validators
import * as CustomValidators from '../../../shared/validators';

// utilities
import { scrollToElement } from 'src/app/utilities/utilities';

@Component({
  selector: 'rcw-registration',
  templateUrl: './registration.component.html',
  host: {
    'class': 'content'
  }
})
export class RegistrationComponent implements OnInit, OnDestroy {

  form: FormGroup;
  step1: FormGroup;
  step2: FormGroup;
  step4: FormGroup;

  private countrySubscription: Subscription;
  public countries: IAutocompleteItem[];
  private institutionSubscription: Subscription;

  private userId: string;
  private userSubscription: Subscription;

  userName: string;
  isUserLoggedIn = false;
  isNotLoggedInMessageShown = false;

  programRolesOptions: string[];
  otherProgramRoleValue: string;
  isOtherProgramRoleInputShown: boolean = false;

  private programRoleFieldSubscription: Subscription;

  private preLoginFormSnapshot: {
    programRole: string;
    otherProgramRole?: string;
    isContentConfirmed: boolean;
    isPrivacyConfirmed: boolean;
    isTermsConfirmed: boolean;
  };

  private mixpanelSubscription: Subscription;

  processing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private analyticsService: AnalyticsService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private institutionService: InstitutionService,
    @Inject(DOCUMENT) private document: HTMLDocument
  ) { }

  ngOnInit(): void {
    this.programRolesOptions = PROGRAM_ROLES;
    this.otherProgramRoleValue = OTHER;
    this.countries = COUNTRIES;

    this.mixpanelSubscription = this.analyticsService.mixpanelInitialized$.subscribe(() => {
      this.analyticsService.trackMixpanelViewEvent('Portal - Registration');
    });

    this.userSubscription = this.userService.user$.subscribe(user => {
      if (user) {
        this.userId = user.id;
        this.userName = user.name;
        this.isUserLoggedIn = true;
      }

      this.initForm(user);
    });
  }

  ngOnDestroy() {
    this.mixpanelSubscription?.unsubscribe();
    this.userSubscription?.unsubscribe();

    this.programRoleFieldSubscription?.unsubscribe();
    this.countrySubscription?.unsubscribe();
    this.institutionSubscription?.unsubscribe();
  }

  private initForm(user: IUser) {
    this.populateFormSteps(user);

    this.form = this.formBuilder.group({
      step1: this.step1,
      step2: this.step2,
      step4: this.step4
    });

    this.setProgramRoleSubscription();
    this.setCountrySubscription();
    this.setInstitutionSubscription();
  }

  private populateFormSteps(user: IUser) {
    this.step1 = this.formBuilder.group({
      programRole: [this.preLoginFormSnapshot?.programRole || this.getProgramRoleFromUser(user?.program_role),
      [Validators.required, Validators.maxLength(250)]],
      otherProgramRole: [{ value: this.getOtherProgramRole(user?.program_role), disabled: !this.getOtherProgramRole(user?.program_role) },
      [Validators.required, Validators.maxLength(250)]]
    });

    this.step2 = this.formBuilder.group({
      isContentConfirmed: [this.preLoginFormSnapshot?.isContentConfirmed || false, [Validators.pattern('true')]],
      isPrivacyConfirmed: [this.preLoginFormSnapshot?.isPrivacyConfirmed || false, [Validators.pattern('true')]],
      isTermsConfirmed: [this.preLoginFormSnapshot?.isTermsConfirmed || false, [Validators.pattern('true')]],
    });

    this.step4 = this.formBuilder.group({
      name: [user?.name || null, [Validators.required, Validators.maxLength(250)]],
      institution: [user?.institution || null, [Validators.required, Validators.maxLength(250)]],
      position: [user?.position || null, [Validators.required, Validators.maxLength(250)]],
      address: [user?.address || null, [Validators.required, Validators.maxLength(250)]],
      city: [user?.city || null, [Validators.required, Validators.maxLength(250)]],
      zipCode: [user?.zip_code || null, [Validators.required, Validators.maxLength(250)]],
      country: [user?.country || null, [Validators.required, Validators.maxLength(250)]],

      linkedinUrl: [user?.linkedin_url || null, [Validators.maxLength(250), CustomValidators.linkedinUrl]],
      profileUrl: [user?.profile_url || null, [Validators.maxLength(250), CustomValidators.url]],

      isstep4Confirmed: [false, [Validators.pattern('true')]]
    });
  }

  private setCountrySubscription() {
    this.countrySubscription = this.step4.controls.country.valueChanges.subscribe(() => {
      this.step4.controls.country.markAsTouched();
    });
  }

  private setInstitutionSubscription() {
    this.institutionSubscription = this.step4.controls.institution.valueChanges.subscribe(() => {
      this.step4.controls.institution.markAsTouched();
    });
  }

  private getProgramRoleFromUser(role: string): string {
    if (!role) {
      return null;
    }

    if (PROGRAM_ROLES.includes(role)) {
      return role;
    }

    return this.otherProgramRoleValue;
  }

  private getOtherProgramRoleFromUser(role: string): string {
    if (!role) {
      return null;
    }

    if (PROGRAM_ROLES.includes(role)) {
      return null;
    }

    this.isOtherProgramRoleInputShown = true;
    return role;
  }

  private getOtherProgramRole(role: string): string {
    if (!this.preLoginFormSnapshot?.programRole) {
      return this.getOtherProgramRoleFromUser(role);
    }

    return (this.preLoginFormSnapshot?.programRole === this.otherProgramRoleValue) ? role : null;
  }

  private setProgramRoleSubscription() {
    this.programRoleFieldSubscription = this.step1.controls.programRole.valueChanges.subscribe((value: string) => {

      if (value !== this.otherProgramRoleValue) {
        this.isOtherProgramRoleInputShown = false;
        this.step1.controls.otherProgramRole.disable();
        return;
      }

      this.isOtherProgramRoleInputShown = true;
      this.step1.controls.otherProgramRole.enable();
      this.step1.controls.otherProgramRole.markAsUntouched();
    });
  }

  private checkUrlValidation() {
    const profileUrl = this.step4.controls.profileUrl;
    const linkedinUrl = this.step4.controls.linkedinUrl;

    if (!this.step4.value.profileUrl && !this.step4.value.linkedinUrl) {
      linkedinUrl.setErrors({ 'urlCombination': true });
      profileUrl.setErrors({ 'urlCombination': true });
    }
  }

  private scrollToFirstInvalidField(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]')
      && document.querySelector('.ng-invalid[formControlName]').parentElement;
    scrollToElement(firstElementWithError);
  }

  onLogout() {
    this.userService.logout();
  }

  onFormSubmit() {
    this.checkUrlValidation();

    this.form.markAllAsTouched();

    if (!this.isUserLoggedIn) {
      this.isNotLoggedInMessageShown = true;
      this.scrollToFirstInvalidField();
      return;
    }

    if (this.form.invalid) {
      this.scrollToFirstInvalidField();
      return;
    }

    const f = this.form.value;

    const body: EnterProgramRequestModel = {
      user: {
        name: f.step4.name,
        institution: f.step4.institution,
        position: f.step4.position,
        address: f.step4.address,
        city: f.step4.city,
        zip_code: f.step4.zipCode,
        country: f.step4.country,

        program_role: (f.step1.programRole !== this.otherProgramRoleValue) ? f.step1.programRole : f.step1.otherProgramRole,
        linkedin_url: f.step4.linkedinUrl,
        profile_url: f.step4.profileUrl
      },
      program: COVID_PROGRAM
    };

    this.processing$.next(true);

    this.userService.enterProgram(body, this.userId).subscribe(response => {
      this.processing$.next(false);
      this.userService.isUserRegistered$.next(true);
    });
  }

  onCountryInput(term: string): Observable<IAutocompleteItem[]> {
    if (!term) {
      return of([]);
    }
    const filteredCountries = this.countries.filter(item => {
      return item.name.toLowerCase().includes(term.toLowerCase());
    });

    return of(filteredCountries);
  }

  onInstitutionInput(term: string): Observable<IAutocompleteItem[]> {
    if (!term) {
      return of([]);
    }
    return this.institutionService.getInstitutions(term);
  }

  onUrlFieldFocus() {
    const linkedinUrl = this.step4.controls.linkedinUrl;
    const profileUrl = this.step4.controls.profileUrl;

    profileUrl.setErrors({ 'urlCombination': false });
    linkedinUrl.setErrors({ 'urlCombination': false });

    profileUrl.updateValueAndValidity();
    linkedinUrl.updateValueAndValidity();
  }

  onLoginClick(event) {
    this.preLoginFormSnapshot = {
      programRole: this.form.value.step1.programRole,
      otherProgramRole: this.form.value.step1.otherProgramRole,
      isContentConfirmed: this.form.value.step2.isContentConfirmed,
      isPrivacyConfirmed: this.form.value.step2.isPrivacyConfirmed,
      isTermsConfirmed: this.form.value.step2.isTermsConfirmed
    };

    event.stopPropagation();
    this.isNotLoggedInMessageShown = false;
    this.userService.isLoginFrameShown$.next(true);
  }
}