// native
import { NgModule } from "@angular/core";

// component
import { RegistrationComponent } from './components/registration/registration.component';

// module
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    RegistrationComponent
  ]
})
export class RegistrationModule { }