<div class="footer_logo-box">
  <a href="https://www.digital-science.com/" target="_blank">
    <img src="assets/img/readcube-logo-text.svg" alt="ReadCube logo">
  </a>
  <a href="https://www.springernature.com" target="_blank">
    <img src="assets/img/partner-logo-sn.svg" alt="ReadCube logo">    
  </a>
  <a href="https://www.wiley.com" target="_blank">
    <img src="assets/img/partner-logo-wiley.svg" alt="ReadCube logo">    
  </a>
  <a href="https://jamanetwork.com/" target="_blank">
    <img src="assets/img/partner-logo-jama.svg" alt="ReadCube logo">    
  </a>
</div>
<div class="footer_text-box">
  <div>
    &#169; 2021 Digital Science & Research Solutions, Inc. All Rights Reserved
  </div>
  <div>
    <a routerLink="terms">Terms of Use</a>
    <span class="footer_terms-updated">Updated: 22 April 2020</span>
  </div>
  <div>
    <a href="https://www.readcube.com/privacy" target="_blank">Privacy Policy</a>
  </div>
</div>
