// native
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription, BehaviorSubject } from 'rxjs';

// service
import { UserService } from 'src/app/core/services/user.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { AnalyticsService } from 'src/app/core/services/analytics.service';

// model
import { RequestProgramRequestModel } from 'src/app/models/request-program.model';

// constants
import { COVID_API_PROGRAM } from 'src/app/constants/constants';

@Component({
  selector: 'rcw-mining',
  templateUrl: './mining.component.html',
  host: {
    'class': 'content'
  }
})
export class MiningComponent implements OnInit, OnDestroy {

  form: FormGroup;
  isProgramRequested: boolean;
  processing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private mixpanelSubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    this.mixpanelSubscription = this.analyticsService.mixpanelInitialized$.subscribe(()=>{
      this.analyticsService.trackMixpanelViewEvent('Portal - Text & Data Mining')
    });

    this.isProgramRequested = this.localStorageService.getByKey('isProgramRequested');
    
    this.initForm();
  }

  ngOnDestroy() {
    this.mixpanelSubscription?.unsubscribe();
  }

  initForm() {
    this.form = this.formBuilder.group({
      info: [null, [Validators.required, Validators.maxLength(1000)]]
    });
  }

  onSubmit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    const body: RequestProgramRequestModel = {
      program: COVID_API_PROGRAM,
      info: this.form.controls.info.value
    };

    this.processing$.next(true);

    this.userService.requestProgram(body).subscribe((response) => {
      this.processing$.next(false);
      this.localStorageService.save('isProgramRequested', true);
      this.isProgramRequested = true;
    }, error => {
      this.processing$.next(false);
    });
  }
}
