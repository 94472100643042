// native
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// component
import { RegistrationComponent } from './registration/components/registration/registration.component';
import { ResourcesComponent } from './resources/components/resources/resources.component';
import { MiningComponent } from './mining/components/mining/mining.component';
import { AboutComponent } from './about/components/about/about.component';
import { SearchComponent } from './search/components/search/search.component';
import { TermsComponent } from './terms/components/terms/terms.component';

// constants
import { RESEARCH_ROUTE, RESOURCES_ROUTE, MINING_ROUTE } from './constants/constants';

const routes: Routes = [{
  path: 'registration',
  component: RegistrationComponent,
  data: {
    name: 'registration'
  }
},
{
  path: 'research',
  component: SearchComponent,
  data: {
    name: RESEARCH_ROUTE
  }
},
{
  path: 'resources',
  component: ResourcesComponent,
  data: {
    name: RESOURCES_ROUTE
  }
},
{
  path: 'mining',
  component: MiningComponent,
  data: {
    name: MINING_ROUTE
  }
},
{
  path: 'about',
  component: AboutComponent,
  data: {
    name: 'about'
  }
},
{
  path: 'terms',
  component: TermsComponent,
  data: {
    name: 'terms'
  }
},
{
  path: '**',
  redirectTo: 'research'
}];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }