// native
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable, Subscription, empty, Subject, BehaviorSubject } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

// service
import { SearchService } from 'src/app/core/services/search.service';
import { ScriptLoadingService } from 'src/app/core/services/script-loading.service';
import { UserService } from 'src/app/core/services/user.service';
import { AnalyticsService } from 'src/app/core/services/analytics.service';

// model
import { IArticle } from 'src/app/models/article.model';
import { IUser } from 'src/app/models/user.model';
import { ISortOptions, ISortOptionItem } from 'src/app/models/sorting.model';

// utilities
import { scrollToTop } from '../../../utilities/utilities';

// constants
import {
  MAX_ABSTRACT_LENGTH,
  COVID19_SEARCH_TERM,
  ACE2_SEARCH_TERM,
  MYOCARDIAL_INJURY_SEARCH_TERM,
  VENTILATOR_SEARCH_TERM,
  MOST_RECENT,
  RELEVANCE,
  ALTMETRIC,
  CITATIONS
} from 'src/app/constants/constants';

@Component({
  selector: 'rcw-search',
  templateUrl: './search.component.html',
  host: {
    '(document:click)': 'onOutsideDropdownClick($event)',
    'class': 'content'
  }
})
export class SearchComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('searchInput') searchInputElement: ElementRef;
  @ViewChild('sortDropdown') sortDropdownElement: ElementRef;
  @ViewChild('sortBox') sortBoxElement: ElementRef;

  articles$: Observable<IArticle[]>;
  articles: IArticle[];
  totalArticles: number;
  userSubscription: Subscription;
  abstractTruncateCount = MAX_ABSTRACT_LENGTH;

  covid19 = COVID19_SEARCH_TERM;
  ace2 = ACE2_SEARCH_TERM;
  myocardialInjury = MYOCARDIAL_INJURY_SEARCH_TERM;
  ventilator = VENTILATOR_SEARCH_TERM;

  sortOptions: ISortOptions = {
    mostRecent: MOST_RECENT,
    relevance: RELEVANCE,
    altmetric: ALTMETRIC,
    citations: CITATIONS
  };
  selectedSortOption: ISortOptionItem = this.sortOptions.relevance;

  term: string;

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadingMore$: Subject<boolean> = new Subject<boolean>();
  scrollContainer: HTMLElement;

  private mixpanelSubscription: Subscription;

  constructor(
    private searchService: SearchService,
    private userService: UserService,
    private scriptLoadingService: ScriptLoadingService,
    private analyticsService: AnalyticsService,
    @Inject(DOCUMENT) private document: HTMLDocument
  ) { }

  ngOnInit(): void {
    this.mixpanelSubscription = this.analyticsService.mixpanelInitialized$.subscribe(() => {
      this.analyticsService.trackMixpanelViewEvent('Portal - Research');
    });

    this.userSubscription = this.userService.user$.subscribe((user: IUser) => {
      !!user && this.searchArticles(null);

      !!user && this.getTotalArticles();
    });

    this.scrollContainer = <HTMLElement>this.document.querySelector('.content-wrapper');
  }

  ngAfterViewInit(): void {
    !this.scriptLoadingService.isInjectableButtonsScriptLoaded && this.scriptLoadingService.loadInjectableButtonScript();
    this.hideSortDropdown();
  }

  ngOnDestroy() {
    this.userSubscription?.unsubscribe();
    this.mixpanelSubscription?.unsubscribe();
  }

  toggleShowAbstract(article: IArticle) {
    article.isFullAbstractShown = !article.isFullAbstractShown;
  }

  toggleShowAuthors(article: IArticle) {
    article.isFullAuthorsShown = !article.isFullAuthorsShown;
  }

  onSearch() {
    this.term = (<HTMLInputElement>this.searchInputElement.nativeElement).value;
    this.searchArticles(this.term);

    this.analyticsService.trackMixpanelSearchEvent('Portal - Search', this.term);
  }

  onAuthorSearch(term: string) {
    this.term = `author:${term}`;
    this.updateCurrentSearchTerm();
    this.searchArticles(this.term);
  }

  onJournalSearch(term: string) {
    this.term = `journal:${term}`;
    this.updateCurrentSearchTerm();
    this.searchArticles(this.term);
  }

  onTitleClick(article: IArticle) {
    const injectableButtonElement = <HTMLElement>this.document.querySelector(`[data-doi="${article.doi}"] .readcube-button-injectable`);

    injectableButtonElement && injectableButtonElement.click();
  }

  onPopularSearch(term: string) {
    this.term = term;
    this.updateCurrentSearchTerm();
    this.searchArticles(this.term);
  }

  updateCurrentSearchTerm() {
    (<HTMLInputElement>this.searchInputElement.nativeElement).value = this.term;
  }

  onClearSearch() {
    this.term = null;
    this.updateCurrentSearchTerm();
    this.searchArticles(this.term);
  }

  searchArticles(term: string, isScroll?: boolean) {
    !isScroll && this.loading$.next(true);
    !isScroll && (this.articles = null);

    isScroll && this.loadingMore$.next(true);
    
    const articles$ = this.searchService.searchArticles((term ? term : null), this.selectedSortOption, isScroll)
      .pipe(
        publishReplay(1),
        refCount()
      );

    articles$.subscribe((articles: IArticle[]) => {
      !isScroll && scrollToTop();
      !isScroll && this.loading$.next(false);

      isScroll && this.loadingMore$.next(false);

      this.articles = articles;
    });

    return articles$;
  }

  selectSortOption(option: ISortOptionItem) {
    this.selectedSortOption = option;
    this.hideSortDropdown();

    this.searchArticles(this.term);
  }

  onOutsideDropdownClick(event) {
    if (!this.sortBoxElement?.nativeElement.contains(event.target)) {
      this.hideSortDropdown();
    }
  }

  hideSortDropdown() {
    this.sortDropdownElement && (this.sortDropdownElement.nativeElement.style.display = 'none');
  }

  showSortDropdown() {
    this.sortDropdownElement.nativeElement.style.display = 'block';
  }

  onScrollList() {
    if (this.searchService.isNextScrollSearchDisabled) {
      return empty();
    }

    return this.searchArticles(this.term, true);
  }

  private getTotalArticles() {
    // if totalArticles are already set in singletion service
    // prevent all further api calls in the current app session
    if (this.searchService.totalArticles) {
      this.totalArticles = this.searchService.totalArticles;
      return;
    }

    this.searchService.getTotalArticles().subscribe((total: number) => {
      this.searchService.totalArticles = total;
      this.totalArticles = total;
    });
  }
}
