// native
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

// service
import { AnalyticsService } from 'src/app/core/services/analytics.service';

@Component({
  selector: 'rcw-about',
  templateUrl: './about.component.html',
  host: {
    'class': 'content'
  }
})
export class AboutComponent implements OnInit {

  private mixpanelSubscription: Subscription;

  constructor(
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    this.mixpanelSubscription = this.analyticsService.mixpanelInitialized$.subscribe(()=>{
      this.analyticsService.trackMixpanelViewEvent('Portal - About the Program')
    });
  }

  ngOnDestroy() {
    this.mixpanelSubscription?.unsubscribe();
  }
}
