<div class="sidebar_item-box">
  <div
    *ngIf="!isUserRegistered" 
    class="sidebar_item"
    routerLink="registration"
    routerLinkActive="sidebar_item--active"
    [routerLinkActiveOptions]="{ exact: true }">
    <div class="sidebar_icon">
      <fa-icon [icon]="['fas', 'tasks']"></fa-icon>
    </div>
    <div class="sidebar_title">
      Registration
    </div>
  </div>
  <div 
    class="sidebar_item"
    routerLink="research"
    routerLinkActive="sidebar_item--active"
    [ngClass]="{'sidebar_item--inactive': !isUserRegistered }">
    <div class="sidebar_icon" style="margin-right: 5px;">
      <fa-icon [icon]="['far', 'file-search']"></fa-icon>
    </div>
    <div class="sidebar_title">
      Research
    </div>
  </div>
  <div 
    class="sidebar_item"
    routerLink="resources"
    routerLinkActive="sidebar_item--active"
    [ngClass]="{'sidebar_item--inactive': !isUserRegistered }">
    <div class="sidebar_icon">
      <fa-icon [icon]="['fas', 'toolbox']"></fa-icon>
    </div>
    <div class="sidebar_title">
      Resources
    </div>
  </div>
  <div 
    class="sidebar_item"
    routerLink="mining"
    routerLinkActive="sidebar_item--active"
    [ngClass]="{'sidebar_item--inactive': !isUserRegistered }">
    <div class="sidebar_icon">
      <fa-icon [icon]="['fal', 'server']"></fa-icon>
    </div>
    <div class="sidebar_title">
      Text &#38; Data Mining
    </div>
  </div>
  <div 
    class="sidebar_item"
    routerLink="about"
    routerLinkActive="sidebar_item--active">
    <div class="sidebar_icon">
      <fa-icon [icon]="['fal', 'info-circle']"></fa-icon>
    </div>
    <div class="sidebar_title">
      About the Program
    </div>
  </div>
</div>