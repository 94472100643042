<div class="header">
  <a 
    class="header_title"
    [routerLink]="isUserRegistered ? 'research' : 'registration'">
    COVID-19 Research Pass
  </a>
  <div class="header_info-box">
    <a 
      *ngIf="user$ | async as user"
      href="https://www.readcube.com/account"
      target="_blank"
      class="header_link">
      {{ user.name }}
    </a>
    <a 
      *ngIf="user$ | async, else signInTemplate"
      (click)="onLogout()" 
      class="header_link">
      Sign out
    </a>
    <ng-template #signInTemplate>
      <fa-icon 
        [icon]="['far', 'sign-in-alt']" 
        class="header_icon">
      </fa-icon>
      <a 
        (click)="onLogin($event)" 
        class="header_link">
        Sign in
      </a>
    </ng-template>
  </div>
</div>