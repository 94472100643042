// native
import { NgModule } from "@angular/core";

// component
import { TermsComponent } from './components/terms/terms.component';

// module
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    TermsComponent
  ]
})
export class TermsModule { }