// native
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

// service
import { AnalyticsService } from 'src/app/core/services/analytics.service';

@Component({
  selector: 'rcw-recources',
  templateUrl: './resources.component.html',
  host: {
    'class': 'content-aside-container'
  }
})
export class ResourcesComponent implements OnInit, OnDestroy {

  private mixpanelSubscription: Subscription;

  constructor(
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    this.mixpanelSubscription = this.analyticsService.mixpanelInitialized$.subscribe(()=>{
      this.analyticsService.trackMixpanelViewEvent('Portal - Resources')
    });
  }

  ngOnDestroy(){
    this.mixpanelSubscription?.unsubscribe();
  }
}
