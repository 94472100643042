// native
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

// service
import { ApiService } from './api.service';
import { AnalyticsService } from './analytics.service';
import { FreshchatService } from './freshchat.service';

// model
import { IUser } from 'src/app/models/user.model';
import { EnterProgramRequestModel } from 'src/app/models/enter-program.model';
import { RequestProgramRequestModel } from 'src/app/models/request-program.model';

// constants
import { COVID_PROGRAM } from '../../constants/constants';

// environment
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user$: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(null);

  isLoginFrameShown$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isUserRegistered$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isUserLoggedIn$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private apiService: ApiService,
    private analyticsService: AnalyticsService,
    private freshchatService: FreshchatService
  ) {
    this.getUser();
  }

  enterProgram(body: EnterProgramRequestModel, userId: string): Observable<any> {
    return this.apiService.post(`${environment.baseUrls.services}/user_account/enter_program`, body).pipe(
      tap(response => {
        // mixpanel tracking enabled in case user just entered the program (there is no refresh of application)
        this.analyticsService.initMixpanel(userId);
      })
    );
  }

  requestProgram(body: RequestProgramRequestModel): Observable<any> {
    return this.apiService.post(`${environment.baseUrls.services}/user_account/request_program`, body);
  }

  logout() {
    const redirectUrl = `${environment.baseUrls.covid}/registration`;
    window.location.href = `${environment.baseUrls.readcube}/session/log_out?redirect=${encodeURIComponent(redirectUrl)}`;
  }

  getUser(): void {
    this.apiService.get(`${environment.baseUrls.services}/users/info_program`).subscribe((response: IUser) => {
      this.user$.next(response);

      this.freshchatService.setFreshchatUser(response);

      if (response.programs.includes(COVID_PROGRAM)) {
        this.isUserRegistered$.next(true);
        this.isUserLoggedIn$.next(true);

        // mixpanel tracking is enabled only for users who participate
        // and will be triggered here on each page get user request
        this.analyticsService.initMixpanel(response.id);

        return;
      }

      this.isUserRegistered$.next(false);
      this.isUserLoggedIn$.next(true);

    },
      error => {
        this.isUserRegistered$.next(false);
        this.isUserLoggedIn$.next(false);
      });
  }
}