import { AbstractControl } from '@angular/forms';

export function url(control: AbstractControl) {
  const value = control.value;
  const regex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

  if (!value) {
    return;
  }

  if (!value.match(regex)) {
    return {
      validUrl: true
    };
  }

  return null;
}