// native
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

// service
import { AnalyticsService } from './analytics.service';
import { UserService } from './user.service';

// models
import { RouteData } from 'src/app/models/route-data';

// environment
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScriptLoadingService {

  constructor(
    private analyticsService: AnalyticsService,
    private userService: UserService,
    @Inject(DOCUMENT) private document: any
  ) { }

  isInjectableButtonsScriptLoaded: boolean;

  loadInjectableButtonScript() {
    const injectableButtonScriptElement = this.document.createElement('script');
    injectableButtonScriptElement.src = environment.injectableButtonUrl;

    injectableButtonScriptElement.onload = () => {
      this.isInjectableButtonsScriptLoaded = true;
    };

    this.document.head.appendChild(injectableButtonScriptElement);
  }

  loadAnalyticsScript(routeData: RouteData) {
    window['dataLayer'] = window['dataLayer'] || [];
    window['gtag'] = function () { window['dataLayer'].push(arguments); };

    window['gtag']('js', new Date());

    const googleTagManagerScript = this.document.createElement('script');
    googleTagManagerScript.src = 'https://www.googletagmanager.com/gtag/js?id=UA-134722866-10';

    googleTagManagerScript.onload = () => {
      this.analyticsService.sendGoogleAnalyticsPageView(routeData);
    };

    this.document.head.appendChild(googleTagManagerScript);
  }

  loadFreshChatScript() {
    const freshChatScript = this.document.createElement('script');
    freshChatScript.src = "https://wchat.freshchat.com/js/widget.js";

    freshChatScript.onload = () => {
      window['fcWidget'] && window['fcWidget'].init({
        token: "3b4355b4-db52-45b0-b73c-0d0c9b9f8763",
        host: "https://wchat.freshchat.com"
      });
    };

    this.document.body.appendChild(freshChatScript);
  }

  loadWistia() {
    const wistiaScript = this.document.createElement('script');
    wistiaScript.src = "https://fast.wistia.net/assets/external/E-v1.js";
    wistiaScript.charset = 'charset=ISO-8859-1'
    this.document.body.appendChild(wistiaScript);
  }
}