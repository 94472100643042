// native
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

// service
import { ApiService } from './api.service';

// models
import { IArticleDataResult, IArticle, IArticleAuthor } from '../../models/article.model';
import { ISortOptionItem } from 'src/app/models/sorting.model';

// environment
import { environment } from '../../../environments/environment';

// constants
import {
  MAX_ABSTRACT_LENGTH,
  MAX_AUTHORS_LENGTH,
  DEFAULT_SEARCH_QUERY,
  DEFAULT_SEARCH_TERM,
  MAX_SEARCH_TERM,
  DEFAULT_SEARCH_SORT_ORDER,
  DEFAULT_TOTAL_QUERY
} from 'src/app/constants/constants';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  totalArticles: number;
  articles: IArticle[];
  scroll_id: string;
  isNextScrollSearchDisabled: boolean = false;

  constructor(
    private apiService: ApiService
  ) { }

  getTotalArticles(): Observable<number> {
    let endpointUrl = `${environment.baseUrls.services}/articles/search?query=${encodeURIComponent(DEFAULT_TOTAL_QUERY)}&size=0&ignore_extids=true`;

    return this.apiService.get(endpointUrl).pipe(
      map(response => response.total)
    );
  }

  searchArticles(term?: string, sortBy?: ISortOptionItem, isScroll?: boolean): Observable<IArticle[]> {
    if (term && term.length > MAX_SEARCH_TERM) {
      return of([]);
    }

    let endpointUrl =
      `${environment.baseUrls.services}/articles/search?query=${encodeURIComponent(DEFAULT_SEARCH_QUERY
        + (term ? term : DEFAULT_SEARCH_TERM))}&ignore_extids=true`;

    if (sortBy && sortBy.value && sortBy.value !== 'relevance') {
      endpointUrl = endpointUrl + `&sort_by=${sortBy.value}&order=${DEFAULT_SEARCH_SORT_ORDER}`;
    }

    if (isScroll) {
      endpointUrl = endpointUrl + `&scroll_id=${this.scroll_id}`;
    }

    return this.apiService.get(endpointUrl).pipe(
      tap((response: IArticleDataResult) => this.saveScrollId(response)),
      map((response: IArticleDataResult) => this.parseSearchResponse(response)),
      tap((results: IArticle[]) => this.disableNextScrollSearchIfNeeded(results, isScroll)),
      map((results: IArticle[]) => this.handleScrollingArticlePopulation(results, isScroll))
    );
  }

  private saveScrollId(response: IArticleDataResult) {
    this.scroll_id = response.scroll_id;
  }

  private disableNextScrollSearchIfNeeded(results: IArticle[], isScroll: boolean) {
    this.isNextScrollSearchDisabled = (isScroll && results.length === 0);
  }

  private parseSearchResponse(response: IArticleDataResult): IArticle[] {
    response.results
      .forEach(result => {
        if (result.year && result.month) {
          result.last_modified = `${result.year}-${result.month}-01`;
        }

        if (result.authors && result.authors.length) {
          result.displayedAuthors = this.getDisplayedAuthors(result.authors, result.journal);
          result.nonDisplayedAuthorsCount = result.authors.length - result.displayedAuthors.length;
        } else {
          result.displayedAuthors = [];
          result.nonDisplayedAuthorsCount = 0;
        }

        result.isFullAuthorsShown = false;
        result.isFullAbstractShown = false;
        result.isAbstractTooLong = result.abstract
          && result.abstract.length && (result.abstract.length > MAX_ABSTRACT_LENGTH);
      });

    return response.results;
  }

  private getDisplayedAuthors(authors: IArticleAuthor[], journal: string): IArticleAuthor[] {
    let totalAuthorChars = 0;
    let maxAuthorChars = MAX_AUTHORS_LENGTH - (journal?.length || 0);

    let displayedAuthorsCount = 0;

    for (const author of authors) {
      totalAuthorChars = totalAuthorChars + author.name.length;
      if (totalAuthorChars > maxAuthorChars) break;
      displayedAuthorsCount++;
    }

    return authors.slice(0, displayedAuthorsCount);
  }

  private handleScrollingArticlePopulation(results: IArticle[], isScroll: boolean) {
    if (!isScroll) {
      this.articles = results;
      return results;
    }
    this.articles = this.articles.concat(results);
    return this.articles;
  }
}
