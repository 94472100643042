<div *ngIf="isVisible">
  <div class="modal_backdrop">
    <div class="modal" #modalBox>
      <iframe 
      class="modal_frame"
      [src]="frameUrl">
    </iframe>
    </div>
  </div>
</div>
