// native
import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

// service
import { UserService } from './core/services/user.service';
import { RoutingService } from './core/services/routing.service';
import { ScriptLoadingService } from './core/services/script-loading.service';
import { AnalyticsService } from './core/services/analytics.service';

// model
import { RouteData } from './models/route-data';

// constants
import { USER_ROUTES, MAX_SUPPORTED_WIDTH, MOBILE_URL } from './constants/constants';

// environment
import { environment } from '../environments/environment';

// utitlities
import { scrollToTop } from './utilities/utilities';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  private isUserRoute: boolean;
  private userRoutes: string[];
  private currentRoute: string;

  scrollToTop: Function = scrollToTop;

  constructor(
    public userService: UserService,
    private routingService: RoutingService,
    private router: Router,
    private route: ActivatedRoute,
    private scriptLoadingService: ScriptLoadingService,
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit() {
    this.userRoutes = USER_ROUTES || [];

    this.checkForMobile();
    this.initResizeSubscription();

    this.initRouteSubscriptions();
  }

  initRouteSubscriptions() {
    // TODO: revisit and optimize

    // triggered on refresh + on each internal route change
    this.routingService.currentRouteData()
      .subscribe((data: RouteData) => {
        // save current route data on each trigger
        this.isUserRoute = this.userRoutes && this.userRoutes.includes(data.name);
        this.currentRoute = data.name;

        // trigger pageview GA event
        const routeData = {
          path: this.router.url,
          name: data.name
        };
        environment.production && this.analyticsService.sendGoogleAnalyticsPageView(routeData);
      });

    // triggered on refresh + on login
    this.userService.isUserRegistered$.subscribe((isRegistered: boolean) => {
      // if user is not registered always redirect to registration route if current route isnt available
      if (!isRegistered && this.isUserRoute) {
        this.router.navigateByUrl('registration');
      }

      // if user is registered and current route is registration redirect to reserach
      if (isRegistered && this.currentRoute === 'registration') {
        this.router.navigateByUrl('research');
      }
    });
  }

  initResizeSubscription() {
    fromEvent(window, 'resize').pipe(
      debounceTime(400)
    ).subscribe(() => {
      this.checkForMobile();
    });
  }

  checkForMobile() {
    if (window && window.innerWidth < MAX_SUPPORTED_WIDTH) {
      window.location.href = MOBILE_URL;
    }
  }

  @HostListener('window:message', ['$event'])
  handleMessage(event: MessageEvent) {
    if (event.data.type === "auth:success") {
      this.userService.isLoginFrameShown$.next(false);

      this.userService.getUser();
    }
  }

  @HostListener('window:load', ['$event'])
  onLoad(event: Event) {
    const routeData: RouteData = {
      path: this.router.url,
      name: this.route.snapshot.firstChild?.data?.name
    };
    environment.production && this.scriptLoadingService.loadAnalyticsScript(routeData);

    this.scriptLoadingService.loadFreshChatScript();
    this.scriptLoadingService.loadWistia();
  }
}
