// native
import { NgModule } from "@angular/core";

// component
import { SearchComponent } from './components/search/search.component';

// module
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    SearchComponent
  ]
})
export class SearchModule { }