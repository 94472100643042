<br>

<h1 class="text--h1">
  Terms of Use
</h1>

<br>

<h2 class="text--title-bold">
  About your access to the COVID-19 Research Pass (the “Service”)
</h2>
<p>
  The Service is provided for use as part of your COVID-19 research efforts during the World Health Organization declaration of COVID-19 as a global pandemic or until further notice. You represent that all registration information you submit is accurate and truthful, and agree not to use or permit the use of any part of the Service, or the results of its use, for any other purpose or in any way / for any period not specifically permitted by these Terms of Use. You are responsible for all activity that occurs under your account. Without limiting the foregoing, you agree not to use any part of the Service for any restricted act listed in the “Acceptable Use” section below.
</p>

<br>

<h2 class="text--title-bold">
  About the research content
</h2>
<p>
  The full text content available via the Service has been made available on behalf of the relevant publisher or rightsholder (“Content Provider”) at their absolute discretion and exclusively for the purposes and period, and subject to the limits, specified, which may vary between content. All rights / permissions are limited to internal usage unless otherwise specified and are subject to change. Text or data mining of content (“TDM”) is not permitted without specific and express permission in respect of that content, and subject to any additional terms as the relevant Content Provider may impose, and use of any results will be subject to the same terms as the original content.
</p>
<br>
<p>
  You acknowledge that the content will be added to, updated and/or parts excluded from time to time. If any content is removed from the Service, the Service is discontinued, or any agreed period of permitted usage expires, you shall cease using (and undertake not to use) the content and permanently delete or destroy all copies in your possession and control (and shall certify such deletion/destruction in writing upon request). Where text and data mining of content is permitted, in particular, such obligation to delete shall apply to all indexes created from the content and other data extracted, created or derived from the content (“TDM Materials”). A Content Provider may determine the exact scope of the TDM Materials to be deleted, or otherwise to be subject to these terms, by written notice to you.
</p>
<br>
<p>
  <b>Important note:</b> In respect of Wiley content, TDM is only permitted for non-commercial purposes and otherwise subject to its terms available at <a href="http://olabout.wiley.com/WileyCDA/Section/id-826542.html">http://olabout.wiley.com/WileyCDA/Section/id-826542.html</a>
</p>
<br>
<p>
  You shall use all reasonable efforts to prevent unauthorized access to or use of the content (and, if applicable, the TDM Materials) in your possession and control.
</p>
<br>
<p>
  For the avoidance of doubt, nothing in these terms permits the re-distribution of any content (and, if applicable, the TDM Materials).
</p>
<br>
<p>
  Any of these terms relevant to the content are included also for the benefit of, and may be enforced by, the respective Content Providers (details of whom can be provided on request) without prejudice to any other rights or remedies they may have by law or otherwise.
</p>
<br>
<p>
  Certain content may be subject to open access licence terms, in which case such terms shall govern its usage (and the other provisions of these terms shall be construed accordingly).
</p>

<br>

<h2 class="text--title-bold">
  Disclaimer
</h2>
<p>
  THE SERVICE IS PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTY, CONDITION OR GUARANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE AND THE CONTENT PROVIDERS DISCLAIM ALL LIABILITY HOWSOEVER CAUSED AND REGARDLESS OF THE LEGAL THEORY ASSERTED, ARISING FROM YOUR USE OF THE SERVICE.
</p>

<br>

<h2 class="text--title-bold">
  Copyright
</h2>
<p>
  You acknowledge that the Service is protected by copyright and other intellectual property and proprietary rights, which we and our licensors (or in the case of third party owned content made available via the Service (“Third Party Content”) and the Content Provider) will retain. No title to or ownership of any portion of the Service, including the content contained therein, or any proprietary right related to the Service, including the content, is or will be transferred pursuant to or by virtue of these Terms of Use.  You will abide by all relevant copyright notices, usage restrictions and other additional licensing limits, and agree that any unauthorized use will result in the automatic termination of any rights granted to you. To the extent there is any conflict between the rights granted in these terms and such additional licensing limits, the latter shall prevail.
</p>

<br>

<h2 class="text--title-bold">
  Acceptable use
</h2>
<p>
  By way of example, and without limitation, you shall not, do or assist, encourage or permit any individual or entity to do any of the following in respect of any part of the Service: illegal or unauthorized activity; distribute, give or allow access to or otherwise make available to any other individual or entity, or frame, mirror, overlay, or employ other technologies used to enclose, display or similar; sell, rent, lease, license, loan, commercialize or use for the benefit of any other person (for the avoidance of doubt, uses in connection with work that is carried out on the request, or at the direction or expense, of a person will be considered as being for the benefit of that person); use to power any products / apps / tools / systems accessed by or on behalf of any other person); send altered, deceptive or false source-identifying information, including “spoofing” or “phishing”, run any form of auto-responder or “spam” or use in connection with any unsolicited communication, advertising or similar; commercialize; translate or create derivative works (and the results of any such activities shall be subject to the same terms as the original part); attempt to bypass any measure intended to prevent or restrict non-authorized use, make use of any means of access not made available for that purpose (including via accounts, computer systems or networks connected to the Service) or probe, scan, or test for vulnerabilities; decipher, decompile, disassemble, reverse engineer or otherwise attempt to derive any source code or underlying ideas or algorithms except to the extent reserved by applicable laws and having given us prior notice; use any manual or automated means, including robots, scripts, or spiders to access, monitor, crawl, scrape, spider or mine, except those expressly authorized (in the case of content, by or on behalf of the Content Provider) in advance in writing.
</p>

<br>

<h2 class="text--title-bold">
  Changes to these Terms
</h2>
<p>
  It is likely that these terms will need to be updated on a regular basis, including to reflect the rapidly changing circumstances in which the Service is provided and modifications to the scope and nature of the Service. You are responsible for reviewing and becoming familiar with any such updates. Your continued use of the Service following the posting of any changes to the Service or otherwise notified to you constitutes acceptance of those updates.
</p>

<br>

<h2 class="text--title-bold">
  General
</h2>
<p>
  Together with the <a href="https://www.readcube.com/terms">general terms of use</a> (to the extent applicable, where reference to the “Service” shall include the Service described herein), including any additional terms referred to, these terms constitute the entire agreement with you relating to the Service. 
</p>
<br>
<p>
  All use of the Service, and its contributors, should be clearly and appropriately acknowledged / attributed. 
</p>
<br>
<p>
  All rights / permissions are granted for the duration of the World Health Organization declaration of COVID-19 as a global pandemic or until further notice and are non-exclusive, non-transferable, revocable and limited.
</p>
<br>
<p>
  Any reference to “Service” in these terms, includes any data, content, service or resource made available via the Service, where the context permits.
</p>
<br>
<p>
  Date: 22 April 2020
</p>
<br>
<br>
<p><a href="https://docs.google.com/document/d/1kCXtedCQ5trc5asL1H9pfJwhn3gdxcuPfCRBYU_MqMw/edit?usp=sharing" target="_blank">Archive of Previous Terms of Use</a></p>
<br>
<br>