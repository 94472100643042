<div class="search-box_bar">
  <input
    #searchInput 
    type="text" 
    [placeholder]=" totalArticles
      ? ('Search ' + (totalArticles | currency: undefined : '' : '1.0-0') + ' full text papers available through the COVID-19 Research Pass')
      : 'Search full text papers available through the COVID-19 Research Pass'"
    (keydown.enter)="onSearch()">
  <div 
    class="search-box_icon-search"
    (click)="onSearch()">
    <fa-icon [icon]="['far', 'search']"></fa-icon>
  </div>
  <div 
    class="search-box_icon-close"
    (click)="onClearSearch()">
    <fa-icon [icon]="['fal', 'times-circle']"></fa-icon>
  </div>
</div>

<div class="search-box_navigation">
  <div>
    Popular searches:
    <span class="search-box_search-item" (click)="onPopularSearch(covid19)">COVID-19</span>
    <span class="search-box_search-item" (click)="onPopularSearch(ace2)">ACE2</span>
    <span class="search-box_search-item" (click)="onPopularSearch(myocardialInjury)">Myocardial Injury</span>
    <span class="search-box_search-item" (click)="onPopularSearch(ventilator)">Ventilator</span>
  </div>
  <div
    #sortBox 
    class="search-box_sort">
    Sort:&nbsp; 
    <strong
      class="pointer"
      (click)="showSortDropdown()">
      {{ selectedSortOption.name }}
    </strong>
    <div 
      class="search-box_sort-icon"
      (click)="showSortDropdown()">
      <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
    </div>
    <div
      #sortDropdown
      class="dropdown">
      <div class="dropdown_item" (click)="selectSortOption(sortOptions.relevance)">{{ sortOptions.relevance.name }}</div>
      <div class="dropdown_item" (click)="selectSortOption(sortOptions.mostRecent)">{{ sortOptions.mostRecent.name }}</div>
      <div class="dropdown_item" (click)="selectSortOption(sortOptions.altmetric)">{{ sortOptions.altmetric.name }}</div>
      <div class="dropdown_item" (click)="selectSortOption(sortOptions.citations)">{{ sortOptions.citations.name }}</div>
    </div>
  </div>
</div>

<div 
  *ngIf="articles"
  infiniteScroll
  [scrollPercent]="80"
  [scrollContainer]="scrollContainer"
  [onScrollCallback]="onScrollList.bind(this)">
  <div 
    *ngIf="!articles.length"
    class="margin-top-sm">
    Unfortunatelly, we found no results for your query. Please try again.
  </div>
  <div 
    *ngFor="let article of articles"
    class="list-item">
    <div 
      class="list-item_title"
      (click)="onTitleClick(article)">
      {{ article.title }}
    </div>
    <div class="list-item_subtitle-box">
      <ng-container *ngIf="!!article.displayedAuthors.length">
        <div 
          *ngFor="let author of (article.isFullAuthorsShown ? article.authors : article.displayedAuthors)"
          class="list-item_author"
          (click)="onAuthorSearch(author && author.name)">
          {{ author.name }}
        </div>
      </ng-container>
      <div 
        *ngIf="!!article.nonDisplayedAuthorsCount"
        (click)="toggleShowAuthors(article)"
        class="badge">
        {{ article.isFullAuthorsShown ? 'less' : ('&#43; ' + article.nonDisplayedAuthorsCount + (article.nonDisplayedAuthorsCount === 1 ? ' author' : ' authors')) }}
      </div>
      <div
        class="pointer"
        *ngIf="article.journal" 
        (click)="onJournalSearch(article.journal)">
        {{ article.journal }}
      </div>
      <div *ngIf="article.last_modified">
        {{ article.last_modified | date: 'MMMM, yyyy' }}
      </div>
    </div>
    <div *ngIf="article.abstract" class="list-item_content">
    {{ (!article.isAbstractTooLong || article.isFullAbstractShown) ? article.abstract : (article.abstract | truncate: abstractTruncateCount) }} 
      <span 
        *ngIf="article.isAbstractTooLong"
        class="badge"
        (click)="toggleShowAbstract(article)">
        {{ article.isFullAbstractShown ? 'less' : 'more' }} 
      </span>
    </div>
    <div class="list-item_button-box">
      <span class="__readcube-access-button" [attr.data-doi]="article.doi"></span>
      <span class="__readcube-library-button" [attr.data-doi]="article.doi"></span>
      <span class="__readcube-share-button" [attr.data-doi]="article.doi"></span>

      <div 
        *ngIf="article.counts && (article.counts.altmetric > 0 || article.counts.citations > 0 || article.counts.rcr > 0)" 
        class="list-item_metrics-box">

        <a 
          *ngIf="article.counts.altmetric > 0" 
          class="list-item_metrics-button" 
          title="View in Altmetric"
          href="https://www.altmetric.com/details.php?domain=covid19.readcube.com&doi={{ article.doi }}&template=dimensions" 
          target="_blank">
          <span>Altmetric</span>
          <span class="badge">{{ article.counts.altmetric.toFixed && article.counts.altmetric.toFixed(0) }}</span>
        </a>
      
        <a 
          *ngIf="article.counts.citations > 0" 
          class="list-item_metrics-button" 
          title="View in Dimensions"
          href="https://badge.dimensions.ai/details/doi/{{ article.doi }}" 
          target="_blank">
          <span>Citations</span>
          <span class="badge">{{ article.counts.citations }}</span>
        </a>
  
        <a 
          *ngIf="article.counts.rcr > 0" 
          class="list-item_metrics-button" 
          title="View in Dimensions"
          href="https://badge.dimensions.ai/details/doi/{{ article.doi }}" 
          target="_blank">
          <span>RCR</span>
          <span class="badge">{{ article.counts.rcr }}</span>
        </a>
      </div>
    </div>
  </div>

  <rcw-loader *ngIf="(loadingMore$ | async)" class="loader_more-container"></rcw-loader>
</div>

<div *ngIf="(loading$ | async)" class="loader_overlay">
  <rcw-loader class="center-horizontal-vertical"></rcw-loader>
</div>