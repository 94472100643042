// native
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

// service
import { UserService } from '../../services/user.service';

// environment
import { environment } from 'src/environments/environment';

@Component({
  selector: 'rcw-auth',
  templateUrl: './auth.component.html',
  host: {
    '(document:click)': 'onOutsideClick($event)',
  }
})
export class AuthComponent implements OnInit {

  isVisible: boolean = false;

  frameUrl: SafeUrl;

  @ViewChild('modalBox') modalElement: ElementRef<HTMLInputElement>;

  constructor(
    private userService: UserService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    const frameUrl =
      `${environment.baseUrls.readcube}/authentication?client=${environment.clientName}&tracking_origin=${environment.clientName}`;

    this.frameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(frameUrl);

    this.userService.isLoginFrameShown$.subscribe((isVisible: boolean) => {
      this.isVisible = isVisible;
    });
  }

  onOutsideClick(event) {
    if (!this.modalElement || !this.modalElement.nativeElement) {
      return;
    }

    if (!this.modalElement.nativeElement.contains(event.target)) {
      this.userService.isLoginFrameShown$.next(false);
    }
  }
}
