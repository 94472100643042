// native
import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// fa icons
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// components
import { LoaderComponent } from './components/loader/loader.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { FieldErrorComponent } from './components/field-error/field-error.component';

// directives
import { InfiniteScrollDirective } from './directives/infinite-scroll.directive';

// pipes
import { TruncatePipe } from './pipes/truncate.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule
  ],
  declarations: [
    LoaderComponent,
    AutocompleteComponent,
    FieldErrorComponent,

    InfiniteScrollDirective,

    TruncatePipe,
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,

    LoaderComponent,
    AutocompleteComponent,
    FieldErrorComponent,

    InfiniteScrollDirective,

    TruncatePipe
  ]
})
export class SharedModule { }