<br>

<div class="image-box">
  <img src="assets/img/about-min.png" alt="about the program">
</div>

<br>

<h1 class="text--h1">
  About the Program
</h1>

<br>

<p>
  By joining the COVID-19 Research Pass, you are provided immediate access to the following resources:
</p>
<ul class="text-list">
  <li class="text-list_item">
    Access to millions of full text articles from leading publishers, including Springer Nature, Wiley, and the Journal of the American Medical Association (JAMA).
  </li>
  <li class="text-list_item">
    Ability to create private and public research groups within which you can share full text articles with notes and annotations for fellow community members.  
  </li>
  <li class="text-list_item">
    Complimentary access to software to facilitate research and sharing from Papers, Figshare, Dimensions, Altmetric, and Overleaf.  
  </li>
</ul>

<br>
<br>

<h1 class="text--h1">
  How to Access Scholarly Articles
</h1>

<br>

<p>
  Under the program there are several ways to access literature, including:
</p>
<ul class="text-list">
  <li class="text-list_item">
    Search for articles by keyword or DOI using the Research tab on the left.  Full text from articles from participating publishers will be immediately accessible.  You may also generate public sharing links to these articles there.  
  </li>
  <li class="text-list_item">
    Get the <a target="_blank" href="https://www.papersapp.com/reference-management-web-importer/">Papers browser extension</a>, which will automatically show full text links to content from participating publishers as you navigate popular sites including Pubmed and publisher web pages.   
  </li>
  <li class="text-list_item">
    Use the Papers desktop and web applications to search for articles and view full text through the program.  See the <a routerLink="resources">Resources</a> tab for more information.  
  </li>
  <li class="text-list_item">
    Search for articles on Dimensions.  As long as you remain signed into your ReadCube account, you will gain access to content through this program directly on Dimensions.
  </li>
</ul>

<br>

<p>
  In addition to direct full text access to individual articles, if you need bulk access to literature for text and data mining purposes, 
  you can sign up for TDM access on the <a routerLink="mining">Text and Data Mining</a> section.
</p>

<br>
<br>

<h1 class="text--h1">
  Video Guides
</h1>

<br>

<div class="text-list_item--arrow-link wistia_embed wistia_async_sicmfse5ry popover=true popoverContent=link 
  stillUrl=data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=">
  <a href="">COVID19 Research Pass Overview</a>
</div>

<br>

<div class="text-list_item--arrow-link wistia_embed wistia_async_r5zebmuqel popover=true popoverContent=link 
  stillUrl=data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=">
  <a href="">Using Your COVID-19 Research Pass Across the Web</a>
</div>

<br>

<div class="text-list_item--arrow-link wistia_embed wistia_async_0h80mbxohx popover=true popoverContent=link 
  stillUrl=data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=">
  <a href="">Collaboration with Shared Libraries</a>
</div>

<br>
<br>

<h1 class="text--h1">
  Frequently Asked Questions
</h1>

<br>

<h2 class="text--title-bold">
  What papers are searchable within the portal?
</h2>
<p>
  The portal offers full-text access to over 26 million papers. These papers include the entire library from Springer Nature, Wiley, and the Journal of the American Medical Association (JAMA), including paywalled content plus open access articles. New papers are added each day.
</p>

<br>

<h2 class="text--title-bold">
  What formats can I export?
</h2>
<p>
  From within the portal, you can download the PDF file itself (with/without annotations), copy “a” styled reference and select option for export to .bib or .ris.
</p>

<br>

<h2 class="text--title-bold">
  Where can I generate sharing links?
</h2>
<p>
  You can generate sharing links within the Research tab on the portal, or by using one of the Papers apps such as the browser extension.
</p>

<br>

<h2 class="text--title-bold">
  Where can I post the sharing links?
</h2>
<p>
  The shareable links can be posted anywhere, including via social channels and on other highly-used sites, institutional repositories and websites, as well as on scholarly collaborative networks.
</p>

<br>

<h2 class="text--title-bold">
  Are there any sharing restrictions for readers accessing the shared link?
</h2>
<p>
  If you post this link on Twitter for instance, readers will have access to a read-only version of the article along with supplements. If they would like to download the PDF, they will need to sign-up for the program.
</p>

<br>

<h2 class="text--title-bold">
  Do I need to install the browser extension to access full-text in the portal?
</h2>
<p>
  No, you don’t. You can access full text via the portal or DImensions without having to install the browser extension. We’d encourage you to try the extension if you can so you can access full-text while you navigate across the web. 
</p>

<br>

<h2 class="text--title-bold">
  How long will this program last?
</h2>
<p>
  We expect to operate this program for the duration of the World Health Organization (WHO) declaration of COVID-19 as a global pandemic. 
</p>

<br>
