import {
  faServer,
  faInfoCircle,
  faTimesCircle,
  faChevronUp
} from '@fortawesome/pro-light-svg-icons';

import {
  faToolbox,
  faTasks,
  faCalendarWeek,
  faCaretDown
} from '@fortawesome/pro-solid-svg-icons';

import {
  faSearch,
  faFileSearch,
  faSignInAlt
} from '@fortawesome/pro-regular-svg-icons';

export const APP_ICONS = [
  faServer,
  faInfoCircle,
  faToolbox,
  faTasks,
  faSearch,
  faFileSearch,
  faSignInAlt,
  faCalendarWeek,
  faCaretDown,
  faTimesCircle,
  faChevronUp
];
