<br>

<div class="image-box">
  <img src="assets/img/tdm-min.png" alt="text and data mining">
</div>

<br>

<h1 class="text--h1">
  Text &#38; Data Mining
</h1>

<br>

<ng-container *ngIf="isProgramRequested, else requestAccessTemplate">
  Thank you for registering for Text &#38; Data Mining access. You will be contacted shortly with more details.
</ng-container>

<ng-template #requestAccessTemplate>
  <p>
    As a Member of the COVID-19 Research Pass, you can request text and data mining access to the full text content available through 
    the program.  Access to this program is approved on a case by case basis.  Please share with us more information about your 
    intended use case so that we can provide you with appropriate API access:
  </p>
  
  <br>
  
  <form [formGroup]="form" *ngIf="form" autocomplete="off">
    <div class="form-group form-group--has-error">
      <textarea
        rows="8"
        formControlName="info"
        id="info"
        class="form-control"
        [ngClass]="{ 'form-control--error': form.controls.info.errors && form.controls.info.touched }"
        type="text">
      </textarea>
      <rcw-field-error class="relative block" [control]="form.controls.info"></rcw-field-error>
    </div>

    <br>
    <br>
  
    <button 
      type="button"
      class="button"
      [disabled]="(processing$ | async)"
      [ngClass]="{ 'button--blue': !form.invalid }"
      (click)="onSubmit()">
      {{ (processing$ | async) ? 'Processing...' : 'Request Access' }}
    </button>
  </form>

  <br>
</ng-template>