// native
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

// addon
import * as mixpanel from 'mixpanel-browser';

// models
import { RouteData } from 'src/app/models/route-data';

// environment
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  mixpanelInitialized$: ReplaySubject<void> = new ReplaySubject<void>(1);

  constructor() { }

  initMixpanel(userId: string) {
    mixpanel.init(environment.mixpanelApiKey, {
      debug: !environment.production,
      track_pageview: false
    });

    mixpanel.identify(userId);

    this.mixpanelInitialized$.next();
  }

  trackMixpanelViewEvent(eventName: string) {
    mixpanel.track(eventName);
  }

  trackMixpanelSearchEvent(eventName: string, term: string) {
    mixpanel.track(eventName, { "Query": term });
  }

  sendGoogleAnalyticsPageView(routeData: RouteData) {
    window['gtag'] && window['gtag']('config', 'UA-134722866-10', {
      'page_title': routeData.name,
      'page_path': routeData.path
    });
  }
}