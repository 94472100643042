// native
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

// fa icons
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// trackJS
import { TrackJS } from 'trackjs';

// component
import { AppComponent } from './app.component';

// module
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { SearchModule } from './search/search.module';
import { RegistrationModule } from './registration/registration.module';
import { TermsModule } from './terms/terms.module';
import { MiningModule } from './mining/mining.module';

// icons
import { APP_ICONS } from './icons/icons';

// environment
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    AppComponent
],
  imports: [
    BrowserModule,
    HttpClientModule,
    CoreModule.forRoot(),
    RegistrationModule,
    SearchModule,
    MiningModule,
    TermsModule,
    FontAwesomeModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { 
  constructor(
    faIconLibrary: FaIconLibrary
  ) {
    faIconLibrary.addIcons(...APP_ICONS);

    TrackJS.install({
      token: environment.trackJsToken,
      application: environment.trackJsApp,
      enabled: environment.trackJsEnabled
    });
  }
 }