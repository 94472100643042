// native
import { Component, OnInit } from '@angular/core';

// service
import { UserService } from '../../services/user.service';

@Component({
  selector: 'rcw-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {

  isUserRegistered: boolean = true;

  constructor(
    public userService: UserService
  ) { }

  ngOnInit(): void {
    this.userService.isUserRegistered$.subscribe((isRegistered: boolean) => {
      this.isUserRegistered = isRegistered;
    });
  }
}
