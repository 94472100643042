// native
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

// service
import { AnalyticsService } from 'src/app/core/services/analytics.service';

@Component({
  selector: 'rcw-terms',
  templateUrl: './terms.component.html',
  host: {
    'class': 'content'
  }
})
export class TermsComponent implements OnInit, OnDestroy {

  private mixpanelSubscription: Subscription;

  constructor(
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    this.mixpanelSubscription = this.analyticsService.mixpanelInitialized$.subscribe(() => {
      this.analyticsService.trackMixpanelViewEvent('Portal - Terms');
    });
  }

  ngOnDestroy() {
    this.mixpanelSubscription?.unsubscribe();
  }
}
