<br>

<div class="image-box">
  <img src="assets/img/registration-min.png" alt="register for program">
</div>

<br>

<div>
  In collaboration with leading scholarly publishers, the COVID-19 Research Pass enables 
  instant full text access to the latest COVID-19 research literature from participating publishers 
  and via Open Access. Additionally, the program offers API access to millions of full text research 
  articles for text and data mining purposes.  More information about the program can be 
  found <a routerLink="/about">here</a>.
</div>

<br>

<h1 class="text--title-bold">
  Getting started
</h1>

<p>
  Please review and enter the following information to request to join the program:
</p>

<br>

<form *ngIf="form" [formGroup]="form">

  <!-- STEP 1 eligibility-->
  <div class="card">
    <div class="card_header">
      1. Eligibility
    </div>
    <div class="card_body">
      <div [formGroup]="step1">

        <div class="margin-bottom-sm">
          This program is available to anyone who needs access to scholarly literature related to COVID-19.  
          <br>
          Please identify in what capacity you intend to join this program: 
        </div>
      
        <div class="row">
          <div class="col md-6 sm-12">
            <div class="form-group form-group--has-error">
                <select 
                  formControlName="programRole"
                  id="programRole"
                  class="form-control"
                  [ngClass]="{ 'form-control--error': step1.controls.programRole.errors && step1.controls.programRole.touched }">
                  <option value="null" selected>Please select...</option>
                  <option 
                    *ngFor="let option of programRolesOptions" 
                    [value]="option">
                    {{ option }}
                  </option>
                  <option [value]="otherProgramRoleValue">{{ otherProgramRoleValue }}</option>
                </select>
              <rcw-field-error [control]="step1.controls.programRole" [fieldName]="'Research Role'"></rcw-field-error>
            </div>
          </div>
      
          <div 
            *ngIf="isOtherProgramRoleInputShown"
            class="col md-6 sm-12">
            <div class="form-group form-group--has-error form-group--inline">
              <div> Please specify: </div>
              <div>
                <input
                formControlName="otherProgramRole"
                id="otherProgramRole"
                class="form-control"
                [ngClass]="{ 'form-control--error': step1.controls.otherProgramRole.errors && step1.controls.otherProgramRole.touched }"
                type="text">
              <rcw-field-error [control]="step1.controls.otherProgramRole" [fieldName]="'Other Research Role'"></rcw-field-error>
              </div>
            </div>
          </div>
        </div>
      
      </div>
    </div>
  </div>
  <!-- END STEP 1 eligibility-->

  <!-- STEP2 program terms-->
  <div class="card">
    <div class="card_header">
      2. Program Terms
    </div>
    <div class="card_body">
      <div [formGroup]="step2">

        <div class="row">
          <div class="col sm-12">
            <div class="form-group form-group--has-error">
              <input
                type="checkbox"
                class="checkbox_input"
                formControlName="isContentConfirmed"
                id="isContentConfirmed">
              <label 
                for="isContentConfirmed"
                class="checkbox_label"
                [ngClass]="{ 'checkbox_label--error': step2.controls.isContentConfirmed.errors && step2.controls.isContentConfirmed.touched }">
                I agree that all content made available through the program will be used exclusively for the purposes of research and analysis, which may include text and data mining where permitted, related to COVID-19 research efforts and not for any other purpose. 
                Acknowledgement of the original source should be provided in any outcomes.
              </label>
              <rcw-field-error [control]="step2.controls.isContentConfirmed"></rcw-field-error>
            </div>
          </div>
        </div>
      
        <div class="row">
          <div class="col sm-12">
            <div class="form-group form-group--has-error">
              <input
                type="checkbox"
                class="checkbox_input"
                formControlName="isPrivacyConfirmed"
                id="isPrivacyConfirmed">
              <label 
                for="isPrivacyConfirmed"
                class="checkbox_label"
                [ngClass]="{ 'checkbox_label--error': step2.controls.isPrivacyConfirmed.errors && step2.controls.isPrivacyConfirmed.touched }">
              Any personal information you provide will be subject to our <a href="https://www.readcube.com/privacy" target="_blank">Privacy Policy</a>. 
              We may also provide your details to the publisher of any content you access, who may contact you about your research and content needs.
              </label>
              <rcw-field-error [control]="step2.controls.isPrivacyConfirmed"></rcw-field-error>
            </div>
          </div>
        </div>
        
        <div class="row">
          <div class="col sm-12">
            <div class="form-group form-group--has-error">
              <input
                type="checkbox"
                class="checkbox_input"
                formControlName="isTermsConfirmed"
                id="isTermsConfirmed">
              <label 
                for="isTermsConfirmed"
                class="checkbox_label"
                [ngClass]="{ 'checkbox_label--error': step2.controls.isTermsConfirmed.errors && step2.controls.isTermsConfirmed.touched }">
                I agree to the <a routerLink="/terms">Terms of Use</a>.
              </label>
              <rcw-field-error [control]="step2.controls.isTermsConfirmed"></rcw-field-error>
            </div>
          </div>
        </div>
      
      </div>
    </div>

  </div>
  <!-- END STEP2 program terms-->


  <!-- STEP3 login-->
  <div class="card card--login">
    <div class="card_header">
      3. Link your ReadCube Papers Account
    </div>

    <div 
      *ngIf="!isUserLoggedIn"
      class="card_body">
      Access to this program is linked to your ReadCube Papers account.  If you do not already have an account, you can register for a free account:
      <br>
      <br>

      <button 
        type="button"
        class="button button--white" 
        (click)="onLoginClick($event)">
        <img src="assets/img/readcube-logo.svg" alt="Readcube logo">
        Sign In or Register
      </button>
    </div>

    <div 
      *ngIf="isUserLoggedIn"
      class="card_body">
      Access to this program is linked to your ReadCube Papers account. 

      <br>
      <br>

      <p>You are signed in as <strong>{{ userName }}</strong>. </p>
      
      <small>Not you? <u><a (click)="onLogout()">Click here to sign out</a></u></small>
    </div>

  </div>

  <div 
    *ngIf="isNotLoggedInMessageShown" 
    style="margin-top: -30px;"
    class="field-error">
    You need to sign in to complete the registration.
  </div>
  <!-- END STEP3 login-->

  <!-- STEP4 details-->
  <div class="card" [class.hidden]="!isUserLoggedIn">
    <div class="card_header">
      4. Additional Contact Information
    </div>
    <div class="card_body">
      <div [formGroup]="step4">

        <div class="margin-bottom-sm">
          To help prevent abuse of the program, please provide the following additional information so that we may validate your identity.  
          Any accounts associated with fraudulent registrations may be disabled. 
        </div>
      
        <div class="row">
      
          <div class="col md-6 sm-12">
      
            <div class="row">
              <div class="col sm-12">
                <div class="form-group form-group--has-error">
                  <label class="form-label" for="name">Full Name</label>
                  <input
                    formControlName="name"
                    id="name"
                    class="form-control"
                    [ngClass]="{ 'form-control--error': step4.controls.name.errors && step4.controls.name.touched }"
                    type="text">
                    <rcw-field-error [control]="step4.controls.name" [fieldName]="'Full name'"></rcw-field-error>
                </div>
              </div>
            </div>
        
            <div class="row">
              <div class="col sm-12">
                <div class="form-group form-group--has-error">
                  <label class="form-label" for="institution">University or Company</label>
                  <rcw-autocomplete
                    formControlName="institution"
                    id="institution"
                    [onInputCallback]="onInstitutionInput.bind(this)"
                    [isError]="step4.controls.institution.errors && step4.controls.institution.touched">
                  </rcw-autocomplete>
                  <rcw-field-error [control]="step4.controls.institution" [fieldName]="'University or Company'"></rcw-field-error>
                </div>
              </div>
            </div>
        
            <div class="row">
              <div class="col sm-12">
                <div class="form-group form-group--has-error">
                  <label class="form-label" for="position">Title or Role</label>
                  <input
                    formControlName="position"
                    id="position"
                    class="form-control"
                    [ngClass]="{ 'form-control--error': step4.controls.position.errors && step4.controls.position.touched }"
                    type="text">
                  <rcw-field-error [control]="step4.controls.position" [fieldName]="'Title or Role'"></rcw-field-error>
                </div>
              </div>
            </div>
      
          </div>
      
          <div class="col md-6 sm-12">
        
            <div class="row">
              <div class="col sm-12">
                <div class="form-group form-group--has-error">
                  <label class="form-label" for="address">Address</label>
                  <input
                    formControlName="address"
                    id="address"
                    class="form-control"
                    [ngClass]="{ 'form-control--error': step4.controls.address.errors && step4.controls.address.touched }"
                    type="text">
                  <rcw-field-error [control]="step4.controls.address" [fieldName]="'Address'"></rcw-field-error>
                </div>
              </div>
            </div>
        
            <div class="row">
              <div class="col sm-7">
                <div class="form-group form-group--has-error">
                  <label class="form-label" for="city">City</label>
                  <input
                    formControlName="city"
                    id="city"
                    class="form-control"
                    [ngClass]="{ 'form-control--error': step4.controls.city.errors && step4.controls.city.touched }"
                    type="text">
                  <rcw-field-error [control]="step4.controls.city" [fieldName]="'City'"></rcw-field-error>
                </div>
              </div>
              <div class="col sm-5">
                <div class="form-group form-group--has-error">
                  <label class="form-label" for="zipCode">Postal Code</label>
                  <input
                    formControlName="zipCode"
                    id="zipCode"
                    class="form-control"
                    [ngClass]="{ 'form-control--error': step4.controls.zipCode.errors && step4.controls.zipCode.touched }"
                    type="text">
                  <rcw-field-error [control]="step4.controls.zipCode" [fieldName]="'Postal Code'"></rcw-field-error>
                </div>
              </div>
            </div>
        
            <div class="row">
              <div class="col sm-12">
                <div class="form-group form-group--has-error">
                  <label class="form-label" for="country">Country</label>
                  <rcw-autocomplete
                    formControlName="country"
                    id="country"
                    [onInputCallback]="onCountryInput.bind(this)"
                    [isSelectionLimited]="true"
                    [selectionPool]="countries"
                    [isError]="step4.controls.country.errors && step4.controls.country.touched">
                  </rcw-autocomplete>
                  <rcw-field-error [control]="step4.controls.country" [fieldName]="'Country'"></rcw-field-error>
                </div>
              </div>
            </div>
      
          </div>
      
        </div>
      
        <p>
          Please provide at least one URL to a public online profile, such as LinkedIn or a lab or employer webpage.  
          This is required to help verify your identity and participation in the program: 
        </p>

        <br>
      
        <div class="row">
          <div class="col sm-12">
            <div class="form-group form-group--has-error">
              <label class="form-label" for="linkedinUrl">LinkedIn Profile</label>
              <input
                formControlName="linkedinUrl"
                (focus)="onUrlFieldFocus()"
                id="linkedinUrl"
                class="form-control"
                [ngClass]="{ 'form-control--error': step4.controls.linkedinUrl.errors && step4.controls.linkedinUrl.touched }"
                type="text">
              <rcw-field-error [control]="step4.controls.linkedinUrl" [fieldName]="'LinkedIn Profile URL'"></rcw-field-error>
            </div>
          </div>
        </div>
      
        <div class="row">
          <div class="col sm-12">
            <div class="form-group form-group--has-error">
              <label class="form-label" for="profileUrl">Lab webpage, academic profile, or company profile website</label>
              <input
                formControlName="profileUrl"
                (focus)="onUrlFieldFocus()"
                id="profileUrl"
                class="form-control"
                [ngClass]="{ 'form-control--error': step4.controls.profileUrl.errors && step4.controls.profileUrl.touched }"
                type="text">
              <rcw-field-error [control]="step4.controls.profileUrl" [fieldName]="'Website URL'"></rcw-field-error>
            </div>
          </div>
        </div>
      
        <div class="row">
          <div class="col sm-12">
            <div class="form-group form-group--has-error">
              <input
                type="checkbox"
                class="checkbox_input"
                formControlName="isstep4Confirmed"
                id="isstep4Confirmed">
              <label 
                for="isstep4Confirmed"
                class="checkbox_label"
                [ngClass]="{ 'checkbox_label--error': step4.controls.isstep4Confirmed.errors && step4.controls.isstep4Confirmed.touched }">
                I confirm the above information is correct.
              </label>
              <rcw-field-error [control]="step4.controls.isstep4Confirmed"></rcw-field-error>
            </div>
          </div>
        </div>
      
      </div>
    </div>
  </div>
  <!-- END STEP4 details-->

  <div class="text--center">
    <button 
      type="button"
      class="button"
      [disabled]="(processing$ | async)"
      [ngClass]="{ 'button--blue': (!form.invalid && isUserLoggedIn) }" 
      (click)="onFormSubmit()">
      {{ (processing$ | async) ? 'Processing...' : 'Register' }}</button>
  </div>

</form>

<br>