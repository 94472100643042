<div class="rcw-container">
  <div class="navbar">
    <rcw-header></rcw-header>
  </div>

  <div class="main">
    <rcw-sidebar class="sidebar"></rcw-sidebar>

    <div class="content-wrapper">
      <div 
        class="to-top-button"
        (click)="scrollToTop()">
        <fa-icon [icon]="['fal', 'chevron-up']"></fa-icon>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>

  <rcw-footer class="footer"></rcw-footer>
</div>

<rcw-auth></rcw-auth>