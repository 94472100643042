<div class="field-error_container">
  <div 
    class="field-error" 
    *ngIf="control.errors && control.touched">

    <div *ngIf="control.errors.required">
      {{ fieldName || 'This field' }} is required.
    </div>

    <div *ngIf="control.errors.maxlength">
      {{ fieldName || 'This field' }} should have maximum of {{ control.errors.maxlength.requiredLength }} characters.
    </div>

    <div *ngIf="control.errors.validUrl">
      {{ fieldName || 'This field' }} should be a valid URL.
    </div>

    <div *ngIf="control.errors.validLinkedinUrl">
      {{ fieldName || 'This field' }} should be a valid LinkedIn URL.
    </div>

    <div *ngIf="control.errors.urlCombination">
      At least one of the LinkedIn Profile URL or Website URL is required.
    </div>

    <div *ngIf="control.errors.pattern && control.errors.pattern.requiredPattern === '^true$'">
      {{ fieldName || 'This field' }} is required.
    </div>
  </div>
</div>
