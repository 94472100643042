<div class="content">
  <br>

  <h1 class="text--h1">
    Software Resources
  </h1>
  
  <br>
  
  <h3 class="text--subtitle">
    COVID-19 Research Pass Browser Extension
  </h3>
  <p>
    While not required to access content through this portal, we highly recommend you install the Papers browser extension which has been updated to support the COVID-19 Research Pass program. This tool will notify you when full text is available through the program on popular sites including Pubmed and publisher websites.
  </p>
  <p>
    &#8594; &nbsp;<a target="_blank" href="https://www.papersapp.com/reference-management-web-importer/">Get the Papers Browser Extension</a>
  </p>
  
  <br>

  <h3 class="text--subtitle">
    Papers
  </h3>
  <p>
    Papers is a reference management tool that allows you to create collaborative groups where you can share research articles with highlights, annotations, and notes.  You can form public or private research groups within the Papers software with article access granted through this initiative.  
  </p>
  <p>
    &#8594; &nbsp;<a target="_blank" href="https://www.papersapp.com/download">Get Papers</a>
  </p>
  
  <br>
  
  <h3 class="text--subtitle">
    Overleaf
  </h3>
  <p>
    Overleaf is a collaborative LaTeX and Rich Text editor that makes the whole process of writing, editing and publishing beautifully typeset and complex documents much quicker and easier. As a remote team themselves, Overleaf know how important it is to be able to collaborate effectively and to assist this they are providing free upgrades to their Overleaf Professional Accounts through the end of May to those in need.
  </p>
  <p>
    &#8594; &nbsp;<a target="_blank" href="https://www.overleaf.com/blog/helping-you-collaborate-on-overleaf-during-the-coronavirus-pandemic">Get Overleaf</a>
  </p>
  
  <br>
  
  <h3 class="text--subtitle">
    Figshare
  </h3>
  <p>
    Figshare is a repository where users can make all of their research outputs available in a citable, shareable and discoverable manner. In an effort to provide publicly accessible research and data Figshare is allowing researchers to publish any COVID-19 related content via a dedicated portal. All researchers will need to do is create an account on Figshare and upload content with COVID-19 as one of the keywords on their metadata form.  
  </p>
  <p>
    &#8594; &nbsp;<a target="_blank" href="https://COVID19.figshare.com">COVID19 Figshare portal</a>
  </p>
  
  <br>
  <br>
  
  <h2 class="text--title">
    Other Online Resources
  </h2>
  
  <ul class="text-list">
    <li class="text-list_item--arrow">
      <a target="_blank" href="https://aws.amazon.com/government-education/nonprofits/disaster-response/diagnostic-dev-initiative/">AWS Diagnostic Development Initiative Web Portal</a>
    </li>
    <li class="text-list_item--arrow">
      <a target="_blank" href="https://benevolent.ai/publications">Benevolent.ai have used AI to recommend existing treatments</a>
    </li>
    <li class="text-list_item--arrow">
      <a target="_blank" href="https://pages.semanticscholar.org/coronavirus-research">CORD-19 dataset</a>
    </li>
    <li class="text-list_item--arrow">
      <a target="_blank" href="https://app.dimensions.ai/discover/publication?search_text=%222019-nCoV%22%20OR%20%22COVID-19%22%20OR%20%E2%80%9CSARS-CoV-2%E2%80%9D%20OR%20((%22coronavirus%22%20%20OR%20%22corona%20virus%22)%20AND%20(Wuhan%20OR%20China))&search_type=kws&search_field=full_search&and_facet_year=2020">Dimensions Query (ordered by Date)</a>   
    </li>
    <li class="text-list_item--arrow">
      <a target="_blank" href="https://app.dimensions.ai/discover/publication?search_text=%222019-nCoV%22%20OR%20%22COVID-19%22%20OR%20%E2%80%9CSARS-CoV-2%E2%80%9D%20OR%20((%22coronavirus%22%20%20OR%20%22corona%20virus%22)%20AND%20(Wuhan%20OR%20China))&search_type=kws&search_field=full_search&and_facet_year=2020&order=altmetric">Dimensions Query (ordered by Altmetric score)</a>      
    </li>
    <li class="text-list_item--arrow">
      <a target="_blank" href="https://www.dimensions.ai/news/dimensions-is-facilitating-access-to-covid-19-research/?utm_source=hootsuite&utm_medium=twitter&utm_campaign=general&utm_term=dsdimensions">Dimensions Extracts</a> 
    </li>
    <li class="text-list_item--arrow">
      <a target="_blank" href="https://protocolsmethods.springernature.com/channels/1912-from-the-editors/posts/58999-protocols-and-methods-on-coronavirus">DRG Protocols and Methods search on Coronavirus</a> 
    </li>
    <li class="text-list_item--arrow"><a target="_blank" href="https://nano.nature.com/search?workflow=nanoObject&term=freeText%3A%22coronavirus%22&new-search=true&sort-by=">Springer Nature Nano coronavirus query</a></li>
    <li class="text-list_item--arrow">
      <a target="_blank" href="https://covid-19.kenedict.com/">An example cluster map </a>
    </li>
    <li class="text-list_item--arrow">
      <a target="_blank" href="https://github.com/gsarti/covid-papers-browser">An example tool on top of CORD dataset</a> 
    </li>
    <li class="text-list_item--arrow">
      <a target="_blank" href="https://colab.research.google.com/drive/1S9PRSwGFcp74bqMbrHnGf_-C-D1EVqwT?usp=sharing#scrollTo=o0ZbZ2Es6b02">Gard’s analysis of COVID content</a>
    </li>
    <li class="text-list_item--arrow">
      <a target="_blank" href="https://www.kaggle.com/allen-institute-for-ai/CORD-19-research-challenge/tasks">Kaggle summarisation tasks</a>
    </li>
    <li class="text-list_item--arrow">
      <a target="_blank" href="https://hive.springernature.com/people/n.campbell@nature.com/blog/2020/03/26/helping-to-fight-covid-19-an-update?commentID=206527&et=blogs.comment.created">Latest HIVE post on SN’s activities</a>
    </li>
    <li class="text-list_item--arrow">
      <a target="_blank" href="https://registry.opendata.aws/cord-19/">Registry of Open Data on AWS, COVID-19 Open Research Dataset (CORD-19)</a>
    </li>
    <li class="text-list_item--arrow">
      <a target="_blank" href="https://rpubs.com/alexeilutay/covid_coverage">RPubs - Coverage of COVID research by the A&I databases </a>
    </li>
    <li class="text-list_item--arrow">
      <a target="_blank" href="https://www.tableau.com/covid-19-coronavirus-data-resources/">Tableau offering free COVID-19 data resource hub</a>
    </li>
    <li class="text-list_item--arrow">
      <a target="_blank" href="https://zenodo.org/communities/covid-19/search?page=1&size=20">Zenodo COVID-19 resources</a>
    </li>
    <li class="text-list_item--arrow">
      <a target="_blank" href="https://cord19.vespa.ai/">Vespa.ai CORD19 Search Engine</a>
    </li>
  </ul>
  
  <br>
</div>

<div class="aside">
  <h2 class="text--title-aside">
    Publisher resources
  </h2>
  
  <ul class="text-list">
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://www.sciencemag.org/coronavirus-research-commentary-and-news?intcmp=ghd_cov">AAAS Science</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://www.acs.org/content/acs/en/covid-19.html">American Chemical Society (ACS)</a>
    </li>
    <li class="text-list_item--arrow-link">
        <a target="_blank" href="https://www.asm.org/">American Society for Microbiology</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://annals.org/aim/pages/coronavirus-content">Annals of Internal Medicine</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://www.bmj.com/coronavirus">The British Medical Journal (BMJ)</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://www.cambridge.org/core/browse-subjects/medicine/coronavirus-free-access-collection">Cambridge University Press (CUP)</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://www.cell.com/2019-nCOV">Cell Press</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="http://www.lungca.org/index.php?journal=01&page=announcement&op=view&path%5B%5D=82">Chinese Journal of Lung Cancer</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://elifesciences.org/search?for=COVID-19">eLife</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://www.elsevier.com/connect/coronavirus-information-center">Elsevier</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://www.embopress.org/covid-19-policies">EMBO Press</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://www.emeraldgrouppublishing.com/promo/coronavirus.htm">Emerald Publishing</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://erj.ersjournals.com/cc/sars-cov-2-and-covid-19">European Respiratory Society</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://f1000.com/prime/covid-19/">F1000</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://coronavirus.frontiersin.org/">Frontiers</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://www.id-hub.com/2020/03/17/in-focus-coronavirus/">Future Science Group (FSG)</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://www.hindawi.com/covid-19-collection/">Hindawi</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://ioppublishing.org/news/coronavirus-articles-free-to-access-content-from-iop-publishing/">IOP Publishing</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://jamanetwork.com/journals/jama/pages/coronavirus-alert">JAMA</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://www.karger.com/Tap/Home/278492/">Karger Publishers</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://www.thelancet.com/coronavirus">The Lancet</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://home.liebertpub.com/lpages/-coronavirus-science-backed-research/250/">Mary Anne Liebert</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://www.mdpi.com/search?sort=pubdate&page_count=200&year_from=1996&year_to=2020&advanced=(%40(title%2Cabstract%2Ckeywords%2Cauthors%2Caffiliations%2Cdoi%2Cfull_text%2Creferences)Covid-19)%7C(%40(title%2Cabstract%2Ckeywords%2Cauthors%2Caffiliations%2Cdoi%2Cfull_text%2Creferences)SARS-CoV-2)%7C(%40(title%2Cabstract%2Ckeywords%2Cauthors%2Caffiliations%2Cdoi%2Cfull_text%2Creferences)coronavirus)&view=default">MDPI</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://www.nejm.org/coronavirus">New England Journal of Medicine (NEJM)</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://academic.oup.com/journals/pages/coronavirus">Oxford University Press</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://blogs.plos.org/plos/2020/01/novel-coronavirus-2019-ncov-outbreak">PLOS</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://www.pnas.org/coronavirus">PNAS</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://rupress.org/pages/response-covid-19-crisi">Rockefeller University Pres</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://royalsocietypublishing.org/topic/special-collections/novel-coronavirus-outbreak">The Royal Society</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://journals.sagepub.com/pb-assets/PDF/SAGE-Publishing_Coronavirus-Related-Articles-1585785327423.pdf">SAGE Publishing</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://www.ssrn.com/index.cfm/en/coronavirus/">SSRN (pre-prints)</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://taylorandfrancis.com/coronavirus/">Taylor & Francis</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://novel-coronavirus.onlinelibrary.wiley.com/">Wiley</a>
    </li>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://www.uptodate.com/register?code=COVID19_31820">Wolters Kluwer - UpToDate</a>
    </li>
    <br>
    <li class="text-list_item--arrow-link">
      <a target="_blank" href="https://readcube.wufoo.com/forms/k1coxjtc1gwvs6k/">Suggest other community resources</a>
    </li>
  </ul>
</div>