// native
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rcw-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
