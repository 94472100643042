// native
import { Injectable } from '@angular/core';

// model
import { IUser } from 'src/app/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class FreshchatService {
  constructor() { }

  setFreshchatUser(user: IUser) {
    window['fcWidget'] && window['fcWidget'].user.setProperties({
      externalId: user.id,
      firstName: user.name,
      user_id: user.id,
      name: user.name,
      email: user.email,
      position: user.position,
      program_role: user.program_role,
      linkedin_url: user.linkedin_url,
      programs: user.programs ? user.programs.join(", ") : ''
    });
  }
}